.contactForm input {
  border: none;
  outline: none;
  margin: 0;
  width: 100%;
}

.inputBorder {
  position: relative;
  margin-bottom: 18px;
  padding: 10px 0;
}

.inputBorder::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: black;
  left: 0;
  bottom: 0;
} 

.contactForm input::placeholder {
  color: #CCC;
}

.contactForm .inputBorder:last-of-type input {
  margin-top: 60px;
}

.contactForm button {
  width: 100%;
  margin: 0;
  margin-top: 15px;
  padding: 10px;
  border: none;
  background: #50624a;
  color: #FFF;
  outline: none;
  transition: 0.2s ease-in-out;
}

.contactForm button:hover {
  background: #796D77;
}