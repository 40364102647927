footer {
  padding: 40px;
}

.footerContainer {
  background-color: #F4F4F4;
  padding: 90px 100px;
  text-align: left;
}

.footerLeftContainer {
  max-width: 350px;
  float: left;
}

.footerLogo {
  width: 200px;
}

.footerBlurb {
  font-size: 25px;
  font-weight: 100;
  margin: 10px 0;
  opacity: 0.3;
  font-family: 'Louis George Cafe', sans-serif;
}

.footerRightContainer {
  text-align: right;
}

.footerAddress {
  margin-top: 0;
  font-size: 30px;
  font-family: 'made_canvaslight', serif;
}

.footerSocialIcons {
  list-style: none;
  margin: 0;
  margin-top: 15px;
  display: inline-block;
}

.footerSocialIcons li {
  float: left;
  padding: 0 12px;
}

.footerSocialIcons li:last-of-type {
  padding-right: 0px;
}

.footerSocialIcons li img {
  max-height: 25px;
}

.footerSocialIcons li:first-of-type img {
  max-height: 21px;
}

.footerSocialIcons li:last-of-type img {
  max-height: 23px;
}

.footerSocialIcons li a {
  opacity: 0.3;
  transition: 0.3s;
  cursor: pointer;
}

.footerSocialIcons li a:hover {
  opacity: 1;
}

@media only screen and (max-width: 1080px) {
  footer {
    padding: 20px;
  }

  .footerContainer {
    padding: 40px;
  }
}

@media only screen and (max-width: 760px) {
  .footerContainer {
    padding: 40px 20px;
  }

  .footerLeftContainer {
    float: none;
    margin-bottom: 20px;
  }

  .footerRightContainer {
    text-align: left;
    display: flex;
    align-items: flex-end;
    position: relative;
  }

  .footerLogo {
    width: 150px;
  }

  .footerAddress {
    font-size: 18px;
    margin: 0;
  }

  .footerSocialIcons {
    padding: 0;
    position: absolute;
    right: 0px;
    bottom: 8px;
    margin-top: 0;
  }

  .footerSocialIcons li:first-of-type {
    padding-left: 0px;
  }
}

