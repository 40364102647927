.navContainer {
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: 'Louis George Cafe', sans-serif;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 10;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  display: flex;
  transition: 0.3s;
  transition-timing-function: ease-out;
}

.navContainer > :global(.row) {
  margin: 0 20%;
  align-self: start;
  margin-top: 260px;
}

.showMenu {
  opacity: 1;
  visibility: visible;
}

.menuContentContainer {
  display: flex;
  align-items: flex-end;
  position: relative;
  top: -100px;
}

.contactDetailsContainer span:first-child {
  margin-bottom: 70px;
  display: block;
}

.contactDetailsContainer a {
  display: block;
  color: inherit;
}

.contactDetails {
  border-right: 1px solid black;
  padding: 0 70px;
}

.socialIcons {
  list-style: none;
  padding: 0;
  margin: 0;
}

.primaryMenu {
  padding-left: 70px;
  max-width: 445px;
  width: 440px;
  height: 360px;
}

.primaryMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.primaryMenu ul li {
  font-family: 'made_canvaslight', serif;
  font-size: 60px;
}

a.strikeThrough,
button.strikeThrough {
  color: inherit;
  text-decoration: none;
  position: relative;
}

button.strikeThrough::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  background-color: black;
  opacity: 0;
}

:global(.home-page) .home button.strikeThrough::after,
:global(.membership-page) .membership button.strikeThrough::after,
:global(.space-page) .space button.strikeThrough::after,
:global(.community-page) .community button.strikeThrough::after,
a.strikeThrough:hover::after,
a.strikeThrough.highlight::after,
button.strikeThrough:hover::after,
button.strikeThrough.highlight::after {
  opacity: 1;
}

:global(.home-page) .home button.strikeThrough::after,
:global(.membership-page) .membership button.strikeThrough::after,
:global(.space-page) .space button.strikeThrough::after,
:global(.community-page) .community button.strikeThrough::after {
  pointer-events: none;
}

.contactSection {
  position: absolute;
  bottom: 125px;
  right: 20%;
}

.contactSection a {
  margin: 0 15px;
  color: inherit;
}

.contactCta {  
  position: relative;
  box-sizing: border-box;
  margin-left: 130px;
}

.contactCta::before {
  content: '';
  border-bottom: 1px solid black;
  width: 100px;
  opacity: 0.4;
  position: absolute;
  left: -123px;
  top: 14px;
}

.addressLink {
  cursor: pointer;
}

.addressLink:hover {
  text-decoration: underline;
}

.locationModal {
  width: 100%;
}

.locationModal h2 {
  font-size: 30px;
}

@media only screen and (max-width: 1600px) {
  .navContainer > :global(.row) {
    margin: 0 10%;
    margin-top: 260px;
  }
}

@media only screen and (max-width: 1069px) {
  .menuContentContainer {
    align-items: unset;
    flex-wrap: wrap;
    max-width: 500px;
  }

  .contactDetailsContainer {
    order: 2;
  }

  .primaryMenu {
    margin-bottom: 100px;
    max-width: none;
  }
}

@media only screen and (max-width: 760px) {
  .navContainer {
    display: block;
  }

  .navContainer > :global(.row) {
    margin: 0 10%;
    margin-top: 150px;
  }

  .menuContentContainer {
    margin-top: 35%;
    position: static;
    padding: 0px 40px;
  }

  .primaryMenu {
    margin-bottom: 30px;
    height: auto;
    width: 100%;
  }

  .primaryMenu ul li {
    font-family: 'made_canvaslight', serif;
    font-size: 36px;
  }

  .contactDetailsContainer {
    padding: 0;
  }

  .contactDetailsContainer span:first-child {
    margin-bottom: 30px;
  }

  .contactSection {
    bottom: 50px;
    right: auto;
  }

  .primaryMenu, .contactDetails {
    padding: 0;
  }

  .contactDetails {
    border: none;
  }

  .locationModal h2 {
    font-size: 22px;
  }

  .contactSection {
    position: static;
    max-width: 100%;
    padding: 40px;
    display: block;
    margin: 0 auto;
    margin-top: 40px;
  }

  .contactSection a {
    margin: 0;
  }

  .contactCta {
    margin: 0;
    float: right;
  }

  .contactCta::before {
    display: none;
  }
  
}