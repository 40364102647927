.productGridContainer {
  padding: 150px 40px;
  max-width: 1900px;
  margin: 0 auto;
}

.productGridContainer :global(.col) {
  padding: 0 20px;
}

.productGridContainer h2 {
  font-family: 'made_canvaslight', serif;
  font-size: 30px;
  font-weight: bold;
  padding: 40px 40px 70px 40px;
  text-align: center;
}

@media only screen and (max-width: 1080px) {
  .productGridContainer {
    padding: 150px 20px;
  }
}

@media only screen and (max-width: 760px) {
  
}
