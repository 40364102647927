.contentContainer {
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.contentContainer h1 {
  max-width: 500px;
}

.content {
  max-width: 550px;
  padding-left: 100px;
}

.centerContainer {
  display: flex;
  margin: 0 auto;
}

@media only screen and (max-width: 1080px) {
  .contentContainer {
    max-width: none;
  }

  .content {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 760px) {
  .contentContainer {
    display: block;
  }

  .centerContainer {
    display: block;
  }

  .contentContainer h1 {
    margin-bottom: 20px;
  }

  .content {
    padding-left: 0px;
  }
}