.loaderCenter {
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
}

.loaderLogo {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.loaderLogo img {
  width: 200px;
}
