.headingContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.headingContainer h3 {
  font-family: 'made_canvaslight', serif;
  font-size: 40px;
  display: inline-block;
}

.headingContainer span {
  font-family: 'made_canvaslight', serif;
  font-size: 18px;
}

.featuresList {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.featuresList li {
  padding: 15px 0;
  padding-left: 25px;
  box-sizing: border-box;
  border-bottom: 1px solid #939393;
  list-style: none;
  position: relative;
}

.featuresList li::before {
  content: '';
  width: 4px;
  height: 4px;
  position: absolute;
  left: 0;
  top: 26px;
  border-radius: 5px;
  background-color: black;
}

.productImage {
  width: 100%;
  height: 350px;
  margin-bottom: 40px;
  background-size: cover;
  background-position: center;
}


@media only screen and (max-width: 1080px) {
  .headingContainer h3 {
    font-size: 24px;
    max-width: 60%;
  }
}

@media only screen and (max-width: 992px) {
  .productGridItem {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 760px) {
  
}
